.fixed-content
    position: fixed

.txt-light
    color: #f0f3f5

ng-select.ng-invalid.ng-dirty .ng-select-container
    border-color: #f86c6b
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8

ng-select.ng-valid.ng-dirty .ng-select-container
    border-color: #4dbd74
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #e6fde8

.bg-muted
    background-color: #3a4248 !important

.stepper-padding
    position: relative
    width: 100%
    padding-right: 1px !important
.stepper-font-size
    font-size: x-small

.tr-active
    border-color: #3a4248 !important
    background-color: #3a4248 !important

.small-number-input
    width: 15%

.modal-lg
    max-width: 1200px

.modal-md
    max-width: 800px

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

/* Firefox */
input[type=number]
    -moz-appearance: textfield
.large-font-size
    font-size: 120%

.buttons-group
    justify-content: flex-end

.modal-md-verification
    max-width: 560px
