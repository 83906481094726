/* You can add global styles to this file, and also import other style files */
@import "variables"

// Import styles
@import "~@coreui/coreui/scss/coreui"

// If you want to add something do it here
@import "custom"

@import '~ngx-toastr/toastr'

